import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Hero from './home/Hero';
import Testimonials from './home/Testimonials';
import Footer from './shared/Footer';
import Symptoms from './home/Symptoms';
import Diagnosis from './home/Diagnosis';
import About from './home/About';
import Quiz from './pages/Quiz';
import Blogs from './features/blogs/components/Blogs';
import BlogDetail from './features/blogs/components/BlogDetail';
import BlogsCarousel from './home/BlogsCarousel';
import BlankPage from './pages/BlankPage';
import Dashboard from './features/user/dashboard/components/Dashboard';
import Login from './features/auth/components/Login';
import Register from './features/auth/components/Register';
import Profile from './features/user/profile/components/Profile';
import Questionnaire from './features/user/questionnaire/components/Questionnaire';
import SleepStudy from './features/user/sleepStudy/components/SleepStudy';
import Consultation from './features/user/consultation/components/Consultation';
import CPAP from './features/user/device/components/Device';
import ForgotPassword from './features/auth/components/ForgotPassword';
import Logout from './features/user/Logout';
import CreateMultipleUsers from './features/admin/components/CreateMultipleUsers';
import Referral from './features/user/referral/components/Referral';
import FAQ from './features/user/faq/components/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ResetPassword from './features/auth/components/ResetPassword';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className="max-w-7xl mx-auto lg:px-8 py-16">
        {children}
    </div>
);

const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Hero />
            <Symptoms />
            <About />
            <Diagnosis />
            <BlogsCarousel />
            <Testimonials />
        </Layout>
    );
};

const BlogPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Blogs />
        </Layout>
    );
};

const BlogDetailPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <BlogDetail />
        </Layout>
    );
};

// A higher-order component to protect routes
const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    const isAuthenticated = localStorage.getItem('IsLoggedIn') === 'true';
    return isAuthenticated ? element : <Navigate to="/user/login" />;
};

const App: React.FC = () => {
    return (
        <div className="font-sans">
            <Navbar />
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/blogs" element={<BlogPage />} />
                    <Route path="/blog/:id" element={<BlogDetailPage />} />
                    <Route path="/blank-page" element={<BlankPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />

                    {/* Admin routes*/}
                    <Route path="/admin/create-users" element={<CreateMultipleUsers />} />

                    {/* Routes for user authentication */}
                    <Route path="/user/login" element={<Login />} />
                    <Route path="/user/register" element={<Register />} />
                    <Route path="/user/forgot-password" element={<ForgotPassword />} />
                    <Route path="/user/reset-password/:token" element={<ResetPassword />} />

                    {/* Protected routes */}
                    <Route path="/user/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                    <Route path="/user/profile" element={<ProtectedRoute element={<Profile />} />} />
                    <Route path="/user/questionnaire" element={<ProtectedRoute element={<Questionnaire />} />} />
                    <Route path="/user/sleep-study" element={<ProtectedRoute element={<SleepStudy />} />} />
                    <Route path="/user/consult" element={<ProtectedRoute element={<Consultation />} />} />
                    <Route path="/user/cpap" element={<ProtectedRoute element={<CPAP />} />} />
                    <Route path="/user/referral" element={<ProtectedRoute element={<Referral />} />} />
                    <Route path="/user/faq" element={<ProtectedRoute element={<FAQ />} />} />
                    <Route path="/user/logout" element={<Logout />} />
                </Routes>
                <Footer />
            </Layout>
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;